import React from "react";
import { ResourceManager } from "../../core/resource-manager";
import { useNavigate } from "react-router-dom";
import bgImg from "../../assets/background.jpeg";

function SuggestedBarsCard(props) {
  const { box } = props;

  const navigate = useNavigate();

  return (
    <div
      className="w-[85%] max-w-lg flex-shrink-0 cursor-pointer rounded-xl border border-gray sm:w-full sm:min-w-[30rem]"
      onClick={() =>
        navigate(box.link, {
          state: {
            title: box.name,
            description: box.description,
          },
        })
      }
    >
      <img
        src={box.image
            ? ResourceManager.getFileUrl(box.image, "box_home")
            : bgImg}
        alt="bar"
        className="h-72 w-full rounded-t-xl object-cover"
      />
      <div className="p-3">
        <h2 className="text--h2 font-bold uppercase">{box.name}</h2>
        <p className="text-grayDark">{box.subtitle}</p>
      </div>
    </div>
  );
}

export default SuggestedBarsCard;
