import * as React from "react";
const HomeBoldSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={34}
    height={34}
    fill="none"
    {...props}
  >
    <path
      stroke="#171717"
      strokeLinecap="round"
      strokeWidth={3}
      d="M28.3 12.189v16.51A3.302 3.302 0 0 1 25 32H8.489a3.302 3.302 0 0 1-3.301-3.302v-16.51M21.697 32v-8.255a3.302 3.302 0 0 0-3.302-3.302h-3.302a3.302 3.302 0 0 0-3.302 3.302V32m19.811-16.51L19.08 2.968a3.302 3.302 0 0 0-4.67 0L1.886 15.491"
    />
  </svg>
);
export default HomeBoldSvg;
