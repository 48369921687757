import { NavLink, Outlet } from "react-router-dom";
import { LoginManager } from "../core/login-manager";
import { LanguageProvider } from "../core/language-provider";
import { PagePath } from "../utils/routes";
import { userStore } from "../store/user";
import cn from "../utils/classNames";
import PageSpinner from "../components/BodyElements/SpinnerBlock/PageSpinner";
import { MdPerson } from "react-icons/md";
import { useEffect, useState } from "react";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import Button from "../components/BodyElements/ButtonBlock/Button";

function AppLayout() {
    const userState = userStore();
    const [isLoggingOut, setIsLoggingOut] = useState(false);
    const userInfo = LoginManager.getUserInfo();
    useEffect(() => {
        userState.refreshUserData();
        console.log(userInfo);
    }, []);

    const logout = async (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: LanguageProvider.get("logout.title"),
            defaultFocus: "reject",
            acceptClassName: "bg-red-500 shadow-none border-none",
            rejectClassName: "shadow-none border-none",
            acceptLabel: LanguageProvider.get("logout.confirm"),
            rejectLabel: LanguageProvider.get("logout.dismiss"),
            accept: async () => {
                setIsLoggingOut(true);
                await LoginManager.logout();
                setIsLoggingOut(false);
            },
        });
    };

    const sideCard = () => {
        return (
            <div className="w-full rounded-2xl border border-gray p-8 lg:w-max lg:flex-shrink-0 lg:pb-32 lg:pr-12 xl:pr-24 ">
                <div className="flex items-center gap-4">
                    <div className="rounded-full bg-secondary p-2">
                        <MdPerson className="h-8 w-8 text-white" />
                    </div>
                    <div>
                        <h2 className="text--h2 font-bold uppercase">
                            {userState.user.name} {userState.user.surname}
                        </h2>
                        <div className="text--smallP">{userState.user.email}</div>
                    </div>
                </div>
                <div className="mt-12 flex flex-col items-start gap-4">
                    <NavLink
                        to={PagePath.UserProfile}
                        className={(state) =>
                            cn("text--largeP", state.isActive && "!font-bold")
                        }
                    >
                        {LanguageProvider.get("settings.edit")}
                    </NavLink>

                    {userInfo.signInType && (userInfo.signInType.value === "STANDARD" || userInfo.signInType === "STANDARD") ? <NavLink
                        to={PagePath.UserEditPassword}
                        className={(state) =>
                            cn("text--largeP", state.isActive && "!font-bold")
                        }
                    >
                        {LanguageProvider.get("settings.edit_password")}
                    </NavLink> : <></>}

                    <NavLink
                        to={PagePath.UserFavorites}
                        className={(state) =>
                            cn("text--largeP", state.isActive && "!font-bold")
                        }
                    >
                        {LanguageProvider.get("settings.favorites")}
                    </NavLink>
                    <NavLink
                        to={PagePath.UserSuggestBar}
                        className={(state) =>
                            cn("text--largeP", state.isActive && "!font-bold")
                        }
                    >
                        {LanguageProvider.get("settings.suggest_bar")}
                    </NavLink>
                    <Button
                        className="text--largeP uppercase text-secondary hover:text-secondaryDark"
                        onClick={logout}
                        isLoading={isLoggingOut}
                    >
                        <span>{LanguageProvider.get("settings.exit")}</span>
                    </Button>
                </div>
                <ConfirmPopup />
            </div>
        );
    };

    if (!userState.user || userState.isUserRefreshing) {
        return <PageSpinner />;
    }

    return (
        <div className="page-content flex flex-1 flex-col items-start gap-8 sm:gap-16 lg:flex-row">
            {sideCard()}
            <Outlet />
        </div>
    );
}

export default AppLayout;
