import React from "react";
import cn from "../../../utils/classNames";

function Input({
  label,
  labelClassName,
  register,
  error,
  wrapClassName,
  className,
  isTextArea,
  ...rest
}) {
  return (
    <div className={cn("flex w-full flex-col", wrapClassName)}>
      {label && <label className={cn("text-left","label", labelClassName)}>{label}</label>}
      {isTextArea ? (
        <textarea
          {...rest}
          {...register}
          className={cn(
            "input disabled:bg-gray-50 disabled:text-gray-400 px-4 py-2",
            error && "!border-red-500",
            className,
          )}
        />
      ) : (
        <input
          {...rest}
          {...register}
          className={cn(
            "input disabled:bg-gray-50 disabled:text-gray-400 px-4 py-2",
            error && "!border-red-500",
            className,
          )}
        />
      )}
      <p className="text-sm italic text-red-500">{error?.message}</p>
    </div>
  );
}

export default Input;
