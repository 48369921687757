import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { LanguageProvider } from "./core/language-provider";
import { LoginManager } from "./core/login-manager";
import { ProgressSpinner } from "primereact/progressspinner";
import { Helmet } from "react-helmet";
import { settings } from "./config/settings";
import { userStore } from "./store/user";
import ScrollToTop from "./utils/ScrollToTop";
import { adminRoutes, baseRoutes, userRoutes } from "./utils/routes";
import UserLayout from "./layout/UserLayout";
import BaseLayout from "./layout/BaseLayout";
import Leaflet from "leaflet";
import icon from "./assets/marker-icon.png";
import iconShadow from "./assets/marker-shadow.png";

import "./styles/index.css";
import "./styles/tailwind.css";
import "./styles/fonts.css";
import "./styles/libExtensions.css";
import "leaflet/dist/leaflet.css";
import "primereact/resources/themes/lara-light-green/theme.css";
import "swiper/css";
import AdminLayout from "./layout/AdminLayout";

// setup for marker icon on the map
let DefaultIcon = Leaflet.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [30, 42],
    iconAnchor: [12, 41],
});

Leaflet.Marker.prototype.options.icon = DefaultIcon;

function App() {
    const [language, setLanguage] = useState("ita");
    const userState = userStore();

    useEffect(() => {
        async function init() {
            var response = await LoginManager.checkLogin();
            console.log("checkLogin", response);

            userState.setLoggedIn(response);
            userState.setUser(LoginManager.getUserInfo());
            userState.refreshUserFavorites();

            // ask user for location and save it in local storage for the moment
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    localStorage.setItem("userLat", latitude);
                    localStorage.setItem("userLng", longitude);
                },
                (err) => {
                    console.error(err);
                },
            );
        }

        init();
        detectSidChange();
    }, []);

    async function detectSidChange() {
        var sessionId = LoginManager.registerChangeSid(async function () {
            var response = await LoginManager.checkLogin();
            console.log("CheckLogin after sid changed", response);

            userState.setLoggedIn(response);
            userState.setUser(LoginManager.getUserInfo());
        });
        console.log("--- SESSION ID " + sessionId + " ---");
    }

    useEffect(() => {
        LanguageProvider.setCurrentLanguage(language);
    }, [language]);

    const authorizedRoutes = (isLoggedIn) => {
        const routesToInject = [...baseRoutes];

        if (isLoggedIn) {
            if (userState?.user?.idBar) {
                routesToInject.push(
                    <Route key="admin-layout" element={<AdminLayout />}>
                        {adminRoutes}
                    </Route>,
                );
            }
            routesToInject.push(
                <Route element={<UserLayout />} key="user-layout">
                    {userRoutes}
                </Route>,
            );
        }

        return routesToInject;
    };

    if (userState.isLoggedIn === null) {
        return (
            <main className="flex h-screen w-full items-center justify-center">
                <ProgressSpinner />
            </main>
        );
    }

    return (
        <ScrollToTop>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{settings.title}</title>
                <meta name="description" content="React application..." />
            </Helmet>
            <Routes>
                <Route element={<BaseLayout />}>
                    {authorizedRoutes(userState.isLoggedIn)}
                </Route>
            </Routes>
        </ScrollToTop>
    );
}

export default App;
