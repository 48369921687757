export const language = {
  defaults: {
    no_results: "Nessun risultato trovato.",
    not_found: "Pagina non trovata.",
    rows: "Righe",
    columns: "Colonne",
    view: "Visualizza",
    edit: "Modifica",
    delete: "Elimina",
    clear: "Resetta",
    image: "Immagine",
    delete_title: "Elimina elemento",
    delete_msg: "Rimuovere elemento?",
    details: "Dettagli",
    close: "Chiudi",
    open: "Apri",
    error: "Errore",
    save: "Salva",
    back: "Torna indietro",
    back_to_home: "Torna alla home",
    search: "Cerca",
    create: "Crea nuovo",
    results_per_page: "Risultati per pagina",
    no_image: "Nessuna immagine.",
    loading: "Attendi un istante...",
    true: "Sì",
    false: "No",
    select: "Seleziona elemento",
    select_file: "Seleziona file",
    add: "Aggiungi",
    options: "Opzioni",
    import: "Importa",
    export: "Esporta",
    paste_here: "Incolla qui",
    check: "Controlla",
    reset: "Reset",
    preview: "Anteprima",
    download: "Scarica",
    open_link: "Apri link",
    open_file: "Apri file",
    elements: "Elementi",
    refresh: "Ricarica",
    clone: "Duplica",
    results: "Risultati",
    link_copied: "Link copiato!",
  },
  errors: {
    insert: "Si è verificato un errore. Controlla i campi e riprova.",
    update: "Si è verificato un errore. Controlla i campi e riprova.",
    get: "Si è verificato un errore.",
    try_later: "Si è verificato un errore. Riprova più tardi.",
  },
  login: {
    title: "Effettua il login",
    subtitle: "Ti diamo il benvenuto su DBars",
    email: "Indirizzo e-mail",
    username: "Utente",
    password: "Password",
    phone: "Telefono",
    confirm_password: "Conferma password",
    login: "Accedi",
    login_subtitle: "Inserisci le tue credenziali",
    register_subtitle: "Inserisci i tuoi dati per continuare",
    register: "Registrati",
    forgot: "Hai dimenticato la password?",
    remember: "Ricordami",
    send_forgot_password_key: "Invia pin",
    reset_password: "Resetta la tua password",
    insert_password_key:
      "Inserisci il pin che hai ricevuto via e-mail e la nuova password. Se non hai ricevuto il pin controlla anche nella posta indesiderata.",
    password_key: "Pin ricevuto",
    new_password: "Nuova password",
    confirm_new_password: "Conferma password",
    confirm_reset: "Conferma",
    back_to_login: "Torna al login",
    change_password: "Devi modificare la password",
    change_password_desc:
      "Riceverai un pin via e-mail da inserire nel prossimo step per modificare la tua password.",
    register_google: "Registrati con Google",
    register_facebook: "Registrati con Facebook",
    login_google: "Accedi con Google",
    login_facebook: "Accedi con Facebook",
    login_no_account: "Non hai un account?",
    login_got_account: "Hai già un account?",
    register_now: "Registrati ora",
    continue: "Continua",
    passwords_not_match: "Le password non coincidono.",
    required: "Campo obbligatorio",
    personal_data: "Conosciamoci meglio",
    firstname: "Nome",
    surname: "Cognome",
    birthday: "Data di nascita",
    address: "Indirizzo",
    city: "Città",
    zip: "CAP",
    state: "Provincia",
    country: "Stato",
    success_registration: "Registrazione avvenuta con successo!",
  },
  logout: {
    title: "Stai per eseguire il logout. Confermare?",
    subtitle:
      "Una volta disconnesso verranno automaticamente rimossi tutti i dati temporanei relativi alla tua sessione. Verranno comunque salvati tutti i progressi e ricaricati al tuo prossimo login.",
    confirm: "Conferma",
    dismiss: "Annulla",
  },
  settings: {
    edit: "Modifica profilo",
    edit_password: "Modifica password",
    favorites: "I tuoi preferiti",
    suggest_bar: "Suggerisci un bar",
    suggest_product: "Suggerisci un prodotto",
    exit: "Esci",
    user_settings: "Impostazioni utente",
    your_profile: "Il tuo profilo",
    save_changes: "Salva modifiche",
    general: "Generali",
    avatar: "Immagine profilo",
    security: "Sicurezza",
    name: "Nome",
    email: "Email",
    username: "Username",
    surname: "Cognome",
    sex: "Sesso",
    general_success: "Azione eseguita con successo.",
    general_save: "Modifica profilo",
    gender: "Genere",
    female: "Donna",
    male: "Uomo",
    other: "Altro",
    password: "Password attuale",
    new_password: "Nuova password",
    confirm_password: "Conferma nuova password",
    security_save: "Modifica password",
    security_success: "Password modificata con successo.",
    passwords_match_error: "Le password non coincidono.",
    avatar_save: "Aggiorna avatar",
    avatar_now: "Immagine profilo attuale",
    avatar_success: "Immagine profilo modificata con successo.",
    bar_name: "Nome bar / insegna",
    bar_address: "Indirizzo bar",
    bar_city: "Città",
    bar_zip: "CAP",
    bar_state: "Provincia",
    bar_phone: "Telefono aziendale",
    bar_email: "Email bar",
    bar_success: "Azione eseguita con successo. Grazie del suggerimento!",
    bar_submit_suggestion: "Invia",
    required: "Campo obbligatorio",
    no_favorites: "Nessun elemento presente",
  },
  header: {
    home: "Home",
    explore: "Esplora",
    bars: "bar",
    products: "prodotti",
    account: "Il mio account",
    help: "Aiuto",
    profile: "Modifica profilo",
    logout: "Esci",
    login_bar: "Sei un bar?",
    profile: "Profilo",
  },
  footer: {
    contacts_title: "Contatti",
    contacts_name: "DBARS srl",
    contacts_place: "via Roma 16, 11908 Genova",
    contacts_email: "info@dbars.com",
    menu_title: "Menu",
    menu_home: "Home",
    menu_bar: "Bar",
    menu_products: "Prodotti",
    menu_explore: "Esplora",
    news_title: "Iscriviti alla newsletter",
    news_placeholder: "Inserisci la tua e-mail",
    social_title: "Social Links",
    copy: "dBars. Tutti i diritti riservati",
  },
  searchbar: {
    search: "Cerca",
    barPlaceholder: "Nome bar",
    productPlaceholder: "Nome prodotto",
    placePlaceholder: "Luogo",
  },
  home: {
    title: "Dove vuoi bere?",
    suggested_title: "Suggeriti per te",
    best_bars_title: "I migliori bar a ",
    view_all: "Vedi tutti",
    about_us_title: "About Us",
    about_us_description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Laoreet id donec ultrices tincidunt arcu non sodales neque. Morbi tristique senectus et netus et malesuada. Pretium aenean pharetra magna ac placerat. Arcu vitae elementum curabitur vitae nunc sed velit dignissim. Mollis nunc sed id semper risus in hendrerit gravida. Mattis nunc sed blandit libero volutpat sed cras ornare. Nisl suscipit adipiscing bibendum est ultricies integer. Nec ultrices dui sapien eget mi proin. Lectus quam id leo in vitae turpis massa. Nulla porttitor massa id neque aliquam vestibulum morbi. Parturient montes nascetur ridiculus mus mauris vitae ultricies leo. Nec feugiat nisl pretium fusce id velit ut tortor pretium. Est pellentesque elit ullamcorper dignissim cras tincidunt lobortis feugiat vivamus. Ac turpis egestas maecenas pharetra convallis posuere morbi. Netus et malesuada fames ac turpis egestas sed tempus. Pellentesque elit eget gravida cum sociis. Vitae sapien pellentesque habitant morbi tristique senectus et netus et.`,
  },
  products: {
    title: "I migliori spirit",
    present_in: "Presente in",
    bar: "bar",
    awarded: "Bottiglia premiata",
    brand: "Brand",
    category: "Categoria",
    grades: "Gradazione",
    region: "Regione",
    related: "Prodotti correlati",
    no_related: "Nessun prodotto correlato",
  },
  congratulations: {
    title: "Congratulazioni!",
    subtitle: "Hai completato la tua registrazione",
    to_profile: "Vai al tuo profilo",
    to_bars: "Esplora bar",
    subtitle1: "Hai appena completato la fase di registrazione!",
    subtitle2: "Entra nella tua dashboard e inserisci tutte le informazioni suol tuo bar.",
    subtitle3: "Completa il tuo profilo seguendo questi semplici step",
    step1_title: "Step 1.",
    step2_title: "Step 2.",
    step3_title: "Step 3.",
    step4_title: "Step 4.",
    step5_title: "Step 5.",
    step1_description: "Inserisci la descrizione, le caratteristiche, gli Orari di apertura e le Immagini del tuo bar.",
    step2_description: "Controlla l’indirizzo di localizzazione del tuo bar.",
    step3_description: "Carica il pdf del tuo menu oppure utilizza la maschera appositamente creata.",
    step4_description: "Stampa il QR Code da mettere dove vuoi per accedere al tuo menu.",
    step5_description: "Appena concluso verrai contatto dal team per confermare la pubblicazione sul sito!",
    to_dashboard: "Vai alla tua dashboard"
  },
  bar: {
    datoMancante: "Da compilare",
    explore: "Esplora bar",
    see_on_map: "Vedi su mappa",
    see_as_list: "Vedi come elenco",
    menu: "Menu",
    closed: "Chiuso",
    reserve: "Prenota",
    reserve_description:
      "Seleziona un metodo per contattare il locale. Verrà aperta l’app corrispondente.",
    phone: "Telefono",
    whatsapp: "Whatsapp",
    description: "Descrizione",
    gallery: "Galleria",
    map: "Indicazioni",
    see_pdf: "Visualizza PDF",
    drink: "Drink",
    spirit: "Spirit",
    wine: "Vini",
    food: "Food",
    pdf: "PDF",
    menu_pdf: "Menù PDF",
    see_googlemaps: "Vedi su Google Maps",
  },
  bar_login: {
    register_title: "Registra il tuo locale su DBars",
    register_subtitle: `Aumenta la tua visibilità, incrementa i guadagni e fidelizza la tua clientela: unisciti ai 10.000 locali partner di DBars, la piattaforma per scoprire i migliori 
    bar in tutta Italia.`,
    info: "Alcune informazioni",
    work_with_us: "Lavorare con Dbars",
    dismiss: "Annulla",
    back: "Indietro",
    personal_info: "Informazioni personali",
    bar_name: "Nome bar",
    vat: "Partita IVA",
    company: "Ragione sociale",
    address: "Indirizzo",
    city: "Città",
    zip: "CAP",
    state: "Provincia",
    country: "Stato",
    bar_info: "Informazioni bar",
    description: "Descrizione",
    features: "Caratteristiche",
    no_mobile: "Per avere accesso a questa sezione, collegarsi da pc o tablet",
  },
  filters: {
    title: "Filtri",
    categories: "Categorie",
    features: "Caratteristiche",
    mood: "Mood",
    price: "Prezzo",
    apply: "Applica filtri",
    clear: "Rimuovi filtri",
    type: "Tipologia",
    categories_spirit: "Categorie spirit",
    alcohol: "Grado alcolico",
    awarded: "Bottiglie premiate",
    wine: "Vini",
    spirit: "Spirit",
    drink: "Drink",
    "0_LOW": "Basso",
    "5_MEDIUM": "Medio",
    "10_HIGH": "Alto",
    min: "Min",
    max: "Max",
  },
  dayOfTheWeek: {
    MONDAY: "Lunedì",
    TUESDAY: "Martedì",
    WEDNESDAY: "Mercoledì",
    THURSDAY: "Giovedì",
    FRIDAY: "Venerdì",
    SATURDAY: "Sabato",
    SUNDAY: "Domenica",
  },
  menu: {
    no_items: "Nessun elemento presente",
  },
  admin: {
    bar: "Bar",
    position: "Posizione",
    menu: "Drink list",
    add: "Aggiungi",
    qrcode: "QR Code",
    profile: "Profilo bar",
    times: "Orari di apertura",
    open: "Aperto",
    closed: "Chiuso",
    open_time: "Orario di apertura",
    close_time: "Orario di chiusura",
    images: "Immagini",
    cover: "Copertina",
    gallery: "Galleria",
    download_qrcode: "Scarica QR Code",
    coordinates: "Coordinate",
    addressLabel: "Indirizzo",
    cityLabel: "Comune",
    zipLabel: "CAP",
    stateLabel: "Provincia",
    countryLabel: "Stato",
    latLabel: "Latitudine",
    lngLabel: "Longitudine",
    businessName: "Ragione sociale",
    vat: "Partita IVA",
    name: "Nome Insegna",
    description: "Descrizione",
    features: "Caratteristiche",
    phone: "Telefono",
    to_dashboard: "Vai alla tua dashboard",
    menu_productName: "Nome",
    label_nomeDrink: "Nome drink",
    label_nomeSpirit: "Nome spirito",
    label_nomeWine: "Nome vino",
    placeholder_nomeDrink: "Es: GIN TONIC",
    placeholder_nomeSpirit: "Es: Macallan 12yo",
    placeholder_nomeWine: "Es: Barolo 2015",
    label_descriptionDrink: "Inserisce una piccola descrizione del tuo cocktail",
    label_descriptionSpirit: "Inserisce una piccola descrizione",
    label_descriptionWine: "Inserisci una piccola descrizione del tuo prodotto",
    placeholder_descriptionDrink: "Es: Ricco e profumato, è caratterizzato da una prevalenza di note floreali",
    placeholder_descriptionSpirit: "Es: molto corposo e dai sapori esotici",
    placeholder_descriptionWine: "Es: vino corposo proveniente dalla regione del barolo",
    label_ingredientsDrink: "Quali sono gli ingredienti utilizzati nel tuo cocktail?",
    placeholder_ingredientsDrink: "Es: Gin e Tonic water",
    label_productsDrink: "Quali prodotti (distillati) utilizzi nel tuo cocktail?",
    placeholder_productsDrink: "Es: Hendricks, Gin Mare",
    placeholder_productsSpirit: "Prodotti",
    label_productsSpirit: "Tagga la bottiglia utilizzata per creare il collegamento alla scheda prodotto",
    label_quantity: "di prodotto?",
    menu_products: "Prodotti",
    menu_ingredients: "Ingredienti",
    menu_unit: "Unità di misura",
    menu_quantity: "Quantità",
    menu_price: "Prezzo",
    menu_visible: "Visibile",
    edit_profile: "Modifica profilo bar",
    edit_position: "Modifica posizione",
    edit_menu_item: "Modifica",
    add_menu_item: "Aggiungi",
    upload_image: "Carica immagine",
    conferma: "Conferma"
  },
  user: {
    exist: "Utente già registrato. Per accedere clicca qui",
    not_bar: "Utente non registrato come bar",
  },
};
