import { create } from "zustand";
import { LoginManager } from "../core/login-manager";
import { ApiManager } from "../core/api-manager";
import { DataManager } from "../core/data-manager";
import { requestErrorToast } from "../utils/toast";

export const userStore = create((set, get) => ({
  user: null,
  setUser: (user) => set({ user }),

  userFavoriteBars: null,
  userFavoriteBarsLoading: false,
  userFavoriteProducts: null,
  userFavoriteProductsLoading: false,

  // ownedBar: null,

  isLoggedIn: null,
  setLoggedIn: (isLoggedIn) => set({ isLoggedIn }),

  isUserRefreshing: false,
  refreshUserData: async () => {
    var res = LoginManager.getUserInfo();
    set({ isUserRefreshing: true });
    var response = await DataManager.getItem("user", res.id);
    if (response.success === 1) {
      var userData = response.body[0];
      console.warn(userData);
      set({ user: userData });
    } else {
      throw response.error;
    }

    set({ isUserRefreshing: false });
  },

  // isBarRefreshing: false,
  // refreshBarData: async () => {
  //   const user = get().user;
  //   set({ isBarRefreshing: true });

  //   // bar id can be the entire bar object or just the id
  //   let barId = user.idBar;
  //   if (typeof barId === "object") barId = barId.id;

  //   var response = await DataManager.getItem("bar", barId);
  //   if (response.success === 1) {
  //     var barData = response.body[0];
  //     set({ ownedBar: barData });
  //   } else {
  //     throw response.error;
  //   }

  //   set({ isBarRefreshing: false });
  // },

  refreshUserFavorites: async () => {
    const user = get().user;
    if (!user) return [];

    set({ userFavoriteBarsLoading: true, userFavoriteProductsLoading: true });
    const sorting = {
      field: "id",
      order: "DESC",
    };
    const constraints = {
      idUser: {
        type: "value",
        value: user.id,
      },
    };
    const barFavorites = DataManager.searchItems(
      "user_favourite_bar",
      1,
      100,
      sorting,
      "",
      [],
      constraints,
    );
    const productsFavorites = DataManager.searchItems(
      "user_favourite_product",
      1,
      100,
      sorting,
      "",
      [],
      constraints,
    );

    const responses = await Promise.all([barFavorites, productsFavorites]);

    if (responses[0].success === 1 && responses[1].success === 1) {
      const bars = responses[0].body;
      const products = responses[1].body;
      set({
        userFavoriteBars: bars,
        userFavoriteProducts: products,
      });
    }

    set({ userFavoriteBarsLoading: false, userFavoriteProductsLoading: false });
  },
  addBarToFavorites: async (bar) => {
    set({ userFavoriteBarsLoading: true });
    const oldFavorites = get().userFavoriteBars;
    const user = get().user;
    if (!user) return;

    // check in API if bar is already in favorites
    const sorting = {
      field: "id",
      order: "DESC",
    };
    const constraints = {
      idBar: {
        type: "value",
        value: bar.id,
      },
      idUser: {
        type: "value",
        value: user.id,
      },
    };
    const res = await DataManager.searchItems(
      "user_favourite_bar",
      1,
      1,
      sorting,
      "",
      [],
      constraints,
    );
    if (res.success === 1 && res.body.length > 0) {
      if (!oldFavorites.find((b) => b.idBar.id === res.body[0].idBar.id)) {
        set({
          userFavoriteBars: [...oldFavorites, res.body[0]],
          userFavoriteBarsLoading: false,
        });
      }
      return;
    }

    const response = await DataManager.insertItem("user_favourite_bar", {
      idBar: {
        id: bar.id,
      },
      idUser: {
        id: get().user.id,
      },
    });
    if (response.success === 1) {
      const barId = response.id;
      const favItem = await DataManager.getItem("user_favourite_bar", barId);
      if (favItem.success === 1) {
        set({
          userFavoriteBars: [...oldFavorites, favItem.body[0]],
        });
      }
    } else {
      requestErrorToast(response.error).fire();
    }
    set({ userFavoriteBarsLoading: false });
  },
  removeBarFromFavorites: async (itemId) => {
    set({ userFavoriteBarsLoading: true });
    const user = get().user;
    const oldFavorites = get().userFavoriteBars;
    if (!user) return;

    // check in API if bar is not in favorites anymore
    const sorting = {
      field: "id",
      order: "DESC",
    };
    const constraints = {
      idBar: {
        type: "value",
        value: itemId.idBar.id,
      },
      idUser: {
        type: "value",
        value: user.id,
      },
    };
    const res = await DataManager.searchItems(
      "user_favourite_bar",
      1,
      1,
      sorting,
      "",
      [],
      constraints,
    );
    if (res.success === 1 && res.body.length === 0) {
      set({
        userFavoriteBars: oldFavorites.filter(
          (b) => b.idBar.id !== itemId.idBar.id,
        ),
        userFavoriteBarsLoading: false,
      });
      return;
    }

    const response = await DataManager.deleteItem(
      "user_favourite_bar",
      itemId.id,
    );
    if (response.success === 1) {
      set({
        userFavoriteBars: oldFavorites.filter((b) => b.id !== itemId.id),
      });
    } else {
      requestErrorToast(response.error).fire();
    }

    set({ userFavoriteBarsLoading: false });
  },
  addProductToFavorites: async (product) => {
    set({ userFavoriteProductsLoading: true });
    const user = get().user;
    const oldFavorites = get().userFavoriteProducts;
    if (!user) return;

    // check in API if product is already in favorites
    const sorting = {
      field: "id",
      order: "DESC",
    };
    const constraints = {
      idProduct: {
        type: "value",
        value: product.id,
      },
      idUser: {
        type: "value",
        value: user.id,
      },
    };
    const res = await DataManager.searchItems(
      "user_favourite_product",
      1,
      1,
      sorting,
      "",
      [],
      constraints,
    );
    if (res.success === 1 && res.body.length > 0) {
      if (
        !oldFavorites.find((p) => p.idProduct.id === res.body[0].idProduct.id)
      ) {
        set({
          userFavoriteProducts: [...oldFavorites, res.body[0]],
          userFavoriteProductsLoading: false,
        });
      }
      return;
    }

    const response = await DataManager.insertItem("user_favourite_product", {
      idProduct: {
        id: product.id,
      },
      idUser: {
        id: get().user.id,
      },
    });
    if (response.success === 1) {
      const productId = response.id;
      const favItem = await DataManager.getItem(
        "user_favourite_product",
        productId,
      );
      if (favItem.success === 1) {
        set({
          userFavoriteProducts: [...oldFavorites, favItem.body[0]],
        });
      }
    } else {
      requestErrorToast(response.error).fire();
    }

    set({ userFavoriteProductsLoading: false });
  },
  removeProductFromFavorites: async (item) => {
    set({ userFavoriteProductsLoading: true });

    const user = get().user;
    const oldFavorites = get().userFavoriteProducts;
    if (!user) return;

    // check in API if product is not in favorites anymore
    const sorting = {
      field: "id",
      order: "DESC",
    };
    const constraints = {
      idProduct: {
        type: "value",
        value: item.idProduct.id,
      },
      idUser: {
        type: "value",
        value: user.id,
      },
    };
    const res = await DataManager.searchItems(
      "user_favourite_product",
      1,
      1,
      sorting,
      "",
      [],
      constraints,
    );
    if (res.success === 1 && res.body.length === 0) {
      set({
        userFavoriteProducts: oldFavorites.filter(
          (p) => p.idProduct.id !== item.idProduct.id,
        ),
        userFavoriteProductsLoading: false,
      });
      return;
    }

    const response = await DataManager.deleteItem(
      "user_favourite_product",
      item.id,
    );
    if (response.success === 1) {
      set({
        userFavoriteProducts: oldFavorites.filter((p) => p.id !== item.id),
      });
    } else {
      requestErrorToast(response.error).fire();
    }

    set({ userFavoriteProductsLoading: false });
  },
}));
