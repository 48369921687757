import { Checkbox as PrCheckbox } from "primereact/checkbox";
import React from "react";
import cn from "../../../utils/classNames";
import { MdCheck } from "react-icons/md";

function Checkbox(props) {
  return (
    <PrCheckbox
      {...props}
      pt={{
        input: ({ props, context }) => ({
          className: cn(
            "rounded-none shadow-none bg-white border-black",
            context.checked && "border-black",
          ),
        }),
      }}
      icon={<MdCheck className="h-6 w-6 text-black" />}
    />
  );
}

export default Checkbox;
