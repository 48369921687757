import React from "react";
import bgImg from "../../assets/background.jpeg";
import BarCategory from "./BarCategory";
import BarTag from "./BarTag";
import BarMood from "./BarMood";
import { useNavigate } from "react-router-dom";
import { PagePath } from "../../utils/routes";
import { ResourceManager } from "../../core/resource-manager";
import { buildPriceLabel } from "../../utils/bars";

function BarCard(props) {
    const bar = props.bar;
    const navigate = useNavigate();

    const getShortText = (description, breapoint) => {
        if (description?.length > breapoint) {
            return description.substring(0, breapoint) + "...";
        }
        return description;
    };

    // const toggleFavorite = async (event) => {
    //   event.stopPropagation();
    //   if (!userState.user) return navigate(PagePath.UserLogin);

    //   if (userState.userFavoriteBars.some((el) => el.id === bar)) {
    //     await userState.removeBarFromFavorites(bar);
    //   } else {
    //     await userState.addBarToFavorites(bar);
    //   }
    // };

    return (
        <div
            className="flex cursor-pointer flex-col rounded-lg border border-gray bg-white p-5 pt-6"
            onClick={() => navigate(PagePath.BarDetails(bar.id))}
        >
            {bar.idCategory && (
                <BarCategory
                    name={bar.idCategory.name}
                    key={`bar-${bar.id}-cat-${bar.idCategory.id}`}
                />
            )}
            <div className="mt-2 flex items-center">
                <h2 className="text--h2 font-bold">{bar.name}</h2>
            </div>
            <p className="!text-base text-grayDark">
                {/* {buildPriceLabel(bar.price?.value, true)} -  */}
                {bar.address}
            </p>
            <p className="text--smallP mb-auto mt-2">
                {getShortText(bar.description, 140)}
            </p>
            <div className="mt-4 flex flex-wrap gap-4">
                {bar.tags?.map((tag) => (
                    <BarTag name={tag.name} key={`bar-${bar.id}-tag-${tag.id}`} />
                ))}
                {bar.mood?.map((m) => (
                    <BarMood name={m.name} key={`bar-${bar.id}-tag-${m.id}`} />
                ))}
                <img
                    src={
                        bar.photoCover
                            ? ResourceManager.getFileUrl(bar.photoCover, "bar")
                            : bgImg
                    }
                    alt="bar"
                    className="h-48 w-full object-cover"
                />
            </div>
        </div>
    );
}

export default BarCard;
