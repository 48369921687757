import * as React from "react";
const MapLightSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={32}
    fill="none"
    {...props}
  >
    <path
      stroke="#171717"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M25.038 13.101c0 4.742-5.868 12.593-9.279 16.757a3.055 3.055 0 0 1-4.764 0C7.585 25.694 1.717 17.843 1.717 13.1 1.717 6.418 6.937 1 13.377 1s11.66 5.418 11.66 12.101Z"
    />
    <path
      stroke="#171717"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M17.709 12.66a4.331 4.331 0 1 1-8.662 0 4.331 4.331 0 0 1 8.662 0Z"
    />
  </svg>
);
export default MapLightSvg;
