import * as React from "react";
const HomeLightSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      stroke="#171717"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M27.415 11.189v16.51A3.302 3.302 0 0 1 24.113 31H7.603a3.302 3.302 0 0 1-3.301-3.302v-16.51M20.812 31v-8.255a3.302 3.302 0 0 0-3.303-3.302h-3.302a3.302 3.302 0 0 0-3.301 3.302V31m19.81-16.51L18.194 1.968a3.302 3.302 0 0 0-4.67 0L1 14.491"
    />
  </svg>
);
export default HomeLightSvg;
