import { ApiManager } from "../core/api-manager";
import { ViewManager } from "../core/view-manager";
import { AuthorizationManager } from "../core/authorization-manager";
import { Utils } from "../core/utils";
import { onUpdate } from "./hooks/onUpdate.js";
import { onDelete } from "./hooks/onDelete.js";
import { onInsert } from "./hooks/onInsert.js";
import { settings } from "./settings.js";

async function suggestBar({ name, email, address, phone }) {
    const body = {
        name,
        email,
        address,
        phone,
    };

    let response = await ApiManager.sendAuthenticatedRequest(
        "/custom/suggest_bar",
        body,
    );

    return response;
}

async function suggestProduct({ name, details }) {
    const body = {
        name,
        details
    };

    let response = await ApiManager.sendAuthenticatedRequest(
        "/custom/suggest_product",
        body,
    );

    return response;
}

/*
    [
        {
            "formattedAddress": "Via dei Carpani, 31033 Castelfranco Veneto TV, Italia",
            "location": {
                "latitude": 45.6800571,
                "longitude": 11.9386896
            }
        }
    ]
*/
async function searchPosition(address) {
    let res = await fetch("https://places.googleapis.com/v1/places:searchText", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-Goog-Api-Key": settings.googleApiKey,
            "X-Goog-FieldMask": "places.formattedAddress,places.location"
        },
        body: JSON.stringify({
            textQuery: address,
        }),
    });

    let data = await res.json();
    return data;
}

async function countBars(productId) {
    const body = {
        id: productId,
    };
    let response = await ApiManager.sendRequest("/custom/count_bars", body);

    return response;
}

async function registerUser(email, password) {
    var response = await ApiManager.sendRequest("/public/register_user", {
        email: email,
        password: Utils.encrypt(password),
    });
    console.warn("register user: ", response);

    return response;
}

async function registerBarUser(email, password) {
    const data = {
        email: email,
        password: Utils.encrypt(password),
    };
    const response = await ApiManager.sendRequest(
        "/public/register_user_business",
        data,
    );
    console.warn("register user: ", response);

    return response;
}

export const CustomDataManager = {
    suggestBar,
    suggestProduct,
    searchPosition,
    countBars,
    registerUser,
    registerBarUser,
};
