import React from "react";

function ArrowLeftSvg(props) {
  return (
    <svg
      width="31"
      height="18"
      viewBox="0 0 31 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M0.954321 8.2045C0.51498 8.64384 0.51498 9.35616 0.954321 9.7955L8.11378 16.955C8.55312 17.3943 9.26543 17.3943 9.70477 16.955C10.1441 16.5156 10.1441 15.8033 9.70477 15.364L3.34081 9L9.70477 2.63604C10.1441 2.1967 10.1441 1.48439 9.70477 1.04505C9.26543 0.605709 8.55312 0.605709 8.11378 1.04505L0.954321 8.2045ZM30.9998 7.875H1.74981V10.125H30.9998V7.875Z"
        fill="#696969"
      />
    </svg>
  );
}

export default ArrowLeftSvg;
