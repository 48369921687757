import React from "react";

function FilterSvg(props) {
  return (
    <svg
      width="36"
      height="21"
      viewBox="0 0 36 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.2353 4.02941H2.47058C2.00255 4.02941 1.55369 3.84349 1.22274 3.51254C0.891795 3.1816 0.705872 2.73274 0.705872 2.26471C0.705872 1.79668 0.891795 1.34782 1.22274 1.01687C1.55369 0.685924 2.00255 0.5 2.47058 0.5H34.2353C34.7033 0.5 35.1522 0.685924 35.4831 1.01687C35.8141 1.34782 36 1.79668 36 2.26471C36 2.73274 35.8141 3.1816 35.4831 3.51254C35.1522 3.84349 34.7033 4.02941 34.2353 4.02941ZM28.3529 12.2647H8.35293C7.8849 12.2647 7.43604 12.0788 7.10509 11.7478C6.77415 11.4169 6.58822 10.968 6.58822 10.5C6.58822 10.032 6.77415 9.58311 7.10509 9.25216C7.43604 8.92122 7.8849 8.73529 8.35293 8.73529H28.3529C28.821 8.73529 29.2698 8.92122 29.6008 9.25216C29.9317 9.58311 30.1176 10.032 30.1176 10.5C30.1176 10.968 29.9317 11.4169 29.6008 11.7478C29.2698 12.0788 28.821 12.2647 28.3529 12.2647ZM21.2941 20.5H15.4118C14.9437 20.5 14.4949 20.3141 14.1639 19.9831C13.833 19.6522 13.647 19.2033 13.647 18.7353C13.647 18.2673 13.833 17.8184 14.1639 17.4875C14.4949 17.1565 14.9437 16.9706 15.4118 16.9706H21.2941C21.7621 16.9706 22.211 17.1565 22.5419 17.4875C22.8729 17.8184 23.0588 18.2673 23.0588 18.7353C23.0588 19.2033 22.8729 19.6522 22.5419 19.9831C22.211 20.3141 21.7621 20.5 21.2941 20.5Z"
        fill="black"
      />
    </svg>
  );
}

export default FilterSvg;
