import React from "react";

function MenuLightSvg(props) {
  return (
    <svg
      width="25"
      height="32"
      viewBox="0 0 25 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.5586 12.25H16.0586C13.9875 12.25 12.3086 10.5711 12.3086 8.5V1M6.68359 25.375H17.9336M6.68359 19.75H12.3086M23.5586 11.9283V27.25C23.5586 29.3211 21.8797 31 19.8086 31H4.80859C2.73752 31 1.05859 29.3211 1.05859 27.25V4.75C1.05859 2.67893 2.73752 1 4.80859 1H12.6303C13.6248 1 14.5787 1.39508 15.282 2.09836L22.4602 9.27664C23.1635 9.97992 23.5586 10.9337 23.5586 11.9283Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default MenuLightSvg;
