import { AutoComplete } from "primereact/autocomplete";
import React from "react";
import { ReactComponent as SearchSvg } from "../../../assets/icons/search.svg";
import { LanguageProvider } from "../../../core/language-provider";
import { useState } from "react";
import { ReactComponent as PinSvg } from "../../../assets/icons/pin.svg";
import FiltersDialog from "./FiltersDialog";
import cn from "../../../utils/classNames";
import Spinner from "../SpinnerBlock/Spinner";
import FilterSvg from "../../../assets/icons/filter";
import { MdClose } from "react-icons/md";

function SearchBarBlock(props) {
  const showNameInput = props.showNameInput ?? true;
  const showPlaceInput = props.showPlaceInput ?? true;
  const showFiltersButton = props.showFiltersButton ?? true;
  const showClearIcon = props.showClearIcon ?? true;
  const isSearching = props.isSearching ?? false;

  const place = props.place;
  const name = props.name;
  const availableFilters = props.availableFilters;
  const activeFilters = props.activeFilters;
  const namePlaceholder = props.namePlaceholder;
  const className = props.className;
  const searchButtonClassName = props.searchButtonClassName;
  const shortSearchButton = props.shortSearchButton;

  const onNameChange = props.onNameChange;
  const onNameClear = props.onNameClear;
  const onPlaceChange = props.onPlaceChange;
  const onPlaceClear = props.onPlaceClear;
  const onSearchNameSuggestions = props.onSearchNameSuggestions;
  const onSearchPlaceSuggestions = props.onSearchPlaceSuggestions;
  const onSearch = props.onSearch;
  const onActiveFiltersChange = props.onActiveFiltersChange;

  const [nameSuggestions, setBarNameSuggestions] = useState([]);
  const [placeSuggestions, setPlaceSuggestions] = useState([]);
  const [filtersDialogShown, setFiltersDialogShown] = useState(false);

  const autocompleteStyles = {
    root: {
      className: cn("outline-0 shadow-none flex-1"),
    },
    input: {
      root: {
        className: cn("border-0 outline-0 shadow-none w-full"),
      },
    },
  };

  const buildPlaceInput = () => {
    return (
      <div
        className={cn(
          "flex w-full flex-1 items-center rounded-xl border border-gray bg-white p-2 sm:border-none sm:p-0",
          shortSearchButton && "border-none",
        )}
      >
        <PinSvg className="ml-2 h-8 w-8" color="red" />
        <AutoComplete
          placeholder={LanguageProvider.get("searchbar.placePlaceholder")}
          value={place}
          field="formattedAddress"
          suggestions={placeSuggestions}
          completeMethod={async (event) => {
            const suggestions = await onSearchPlaceSuggestions(event);
            setPlaceSuggestions(suggestions);
          }}
          onChange={onPlaceChange}
          pt={autocompleteStyles}
        />
        {place && showClearIcon && (
          <button className="mr-4" onClick={onPlaceClear}>
            <MdClose className="h-6 w-6" />
          </button>
        )}
      </div>
    );
  };

  const buildNameInput = () => {
    return (
      <div className="flex w-full flex-1 items-center rounded-xl border border-gray bg-white p-2 sm:border-none sm:p-0">
        <SearchSvg className="ml-2 h-8 w-8" />
        <AutoComplete
          placeholder={namePlaceholder}
          value={name}
          suggestions={nameSuggestions}
          completeMethod={async (event) => {
            const suggestions = await onSearchNameSuggestions(event);
            setBarNameSuggestions(suggestions);
          }}
          onChange={onNameChange}
          pt={autocompleteStyles}
        />
        {name && showClearIcon && (
          <button className="mr-4" onClick={onNameClear}>
            <MdClose className="h-6 w-6" />
          </button>
        )}
      </div>
    );
  };

  return (
    <>
      <div
        className={cn(
          "flex flex-col items-center gap-y-3 rounded-xl sm:flex-row sm:border sm:border-gray sm:bg-white sm:p-2",
          shortSearchButton && "w-full flex-row border-gray bg-white px-2",
          className,
        )}
      >
        {showPlaceInput && buildPlaceInput()}
        {showNameInput && showPlaceInput && (
          <div className="mr-2 hidden h-10 flex-shrink-0 border border-gray sm:flex " />
        )}
        {showNameInput && buildNameInput()}
        <div
          className={cn(
            "flex w-full items-center gap-4 sm:w-max",
            shortSearchButton && "w-max",
          )}
        >
          {showFiltersButton && (
            <button
              onClick={() => setFiltersDialogShown(true)}
              className="order-3 mr-3 sm:order-1 sm:mr-6"
            >
              <FilterSvg className="h-8 w-8" />
            </button>
          )}
          {shortSearchButton ? (
            <button className="btn--secondary px-0" onClick={onSearch}>
              <SearchSvg className="mx-3 block h-6 w-6 sm:hidden" />
              <span className="text--h2 hidden px-6 sm:block">
                {LanguageProvider.get("searchbar.search")}
              </span>
            </button>
          ) : (
            <button
              className={cn(
                "text--h2 order-2 h-14 w-full px-0 sm:w-28 sm:flex-shrink-0",
                searchButtonClassName
                  ? searchButtonClassName
                  : "btn--secondary",
              )}
              onClick={onSearch}
            >
              {isSearching ? (
                <Spinner className="mx-9" />
              ) : (
                <span className="px-6">
                  {LanguageProvider.get("searchbar.search")}
                </span>
              )}
            </button>
          )}
        </div>
      </div>
      <FiltersDialog
        isLoading={availableFilters === undefined}
        visible={filtersDialogShown}
        onHide={() => setFiltersDialogShown(false)}
        availableFilters={availableFilters}
        onActiveFiltersChange={(newFilters) => {
          onActiveFiltersChange(newFilters);
          setFiltersDialogShown(false);
        }}
        activeFilters={activeFilters}
        applyButtonClassName={searchButtonClassName}
      />
    </>
  );
}

export default SearchBarBlock;
