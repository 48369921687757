import React from "react";

function MenuBoldSvg(props) {
  return (
    <svg
      width="27"
      height="34"
      viewBox="0 0 27 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24.5566 13.25H17.0566C14.9855 13.25 13.3066 11.5711 13.3066 9.5V2M7.68164 26.375H18.9316M7.68164 20.75H13.3066M24.5566 12.9283V28.25C24.5566 30.3211 22.8778 32 20.8066 32H5.80664C3.73557 32 2.05664 30.3211 2.05664 28.25V5.75C2.05664 3.67893 3.73557 2 5.80664 2H13.6284C14.6229 2 15.5767 2.39508 16.28 3.09836L23.4583 10.2766C24.1616 10.9799 24.5566 11.9337 24.5566 12.9283Z"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default MenuBoldSvg;
