import * as React from "react";
const ProfileSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={31}
    fill="none"
    {...props}
  >
    <path
      fill="#1C1C1C"
      d="M13.24 17.417c-5.33 0-9.82 3.607-11.157 8.514-.447 1.643.975 3.048 2.678 3.048h16.958c1.703 0 3.125-1.405 2.677-3.048-1.337-4.907-5.825-8.514-11.156-8.514ZM18.635 7.396a5.396 5.396 0 1 1-10.791 0 5.396 5.396 0 0 1 10.791 0Z"
    />
    <path
      stroke="#1C1C1C"
      strokeLinecap="round"
      strokeWidth={2.396}
      d="M13.24 17.417c-5.33 0-9.82 3.607-11.157 8.514-.447 1.643.975 3.048 2.678 3.048h16.958c1.703 0 3.125-1.405 2.677-3.048-1.337-4.907-5.825-8.514-11.156-8.514ZM18.635 7.396a5.396 5.396 0 1 1-10.791 0 5.396 5.396 0 0 1 10.791 0Z"
    />
  </svg>
);
export default ProfileSvg;
