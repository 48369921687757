import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { userStore } from "../store/user";
import PageSpinner from "../components/BodyElements/SpinnerBlock/PageSpinner";

function AdminLayout() {
  const userState = userStore();

  useEffect(() => {
    userState.refreshUserData();
  }, []);

  if (
    !userState.user ||
    userState.isUserRefreshing ||
    // prevent access when bar details are not loaded yet
    typeof userState.user.idBar === "number"
  ) {
    return <PageSpinner />;
  }

  return (
    <>
      <Outlet />
    </>
  );
}

export default AdminLayout;
