import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Accordion, AccordionTab } from "primereact/accordion";
import { LanguageProvider } from "../../../core/language-provider";
import Checkbox from "../CheckboxBlock/Checkbox";
import Spinner from "../SpinnerBlock/Spinner";
import cn from "../../../utils/classNames";
import { MdAdd, MdClose, MdMaximize } from "react-icons/md";
import { InputSwitch } from "primereact/inputswitch";
import Input from "../InputBlock/Input";

function FiltersDialog(props) {
    const visible = props.visible ?? false;
    const onHide = props.onHide;
    const onActiveFiltersChange = props.onActiveFiltersChange;
    const availableFilters = props.availableFilters ?? [];
    const isLoading = props.isLoading ?? false;
    const applyButtonClassName = props.applyButtonClassName;

    const [activeFilters, setActiveFilters] = useState([]);
    const [openedTabs, setOpenedTabs] = useState([]);

    const tabStyles = {
        root: {
            className: "border-b border-gray",
        },
        headerAction: {
            className: "bg-white border-none p-4",
        },
        headerIcon: {
            className: "hidden",
        },
        content: {
            className: "bg-white border-none",
        },
    };

    useEffect(() => {
        if (visible) {
            setActiveFilters(props.activeFilters);
        }
    }, [visible]);

    const toggleFilter = (filter) => {
        const index = activeFilters.findIndex(
            (el) => el.id == filter.id && el.group === filter.group,
        );

        if (index === -1) {
            setActiveFilters([...activeFilters, filter]);
        } else {
            setActiveFilters((prev) => {
                const newFilters = [...prev];
                newFilters.splice(index, 1);
                return newFilters;
            });
        }
    };

    const buildCheckBoxFilter = (filter, index) => {
        return (
            <AccordionTab
                key={`filter-${filter.name}`}
                pt={tabStyles}
                headerTemplate={
                    <div className="flex w-full items-center justify-between">
                        <h3 className="text--h3 uppercase text-black">{filter.label}</h3>
                        <span>
                            {openedTabs.some((el) => el === index) ? (
                                <MdMaximize className="h-5 w-5 text-secondary" />
                            ) : (
                                <MdAdd className="h-5 w-5 text-secondary" />
                            )}
                        </span>
                    </div>
                }
            >
                <div className="flex flex-col gap-3">
                    {filter.options.map((option) => (
                        <div
                            className="flex items-center"
                            key={`checkbox-${option.id}-${option.name}`}
                        >
                            <Checkbox
                                inputId={`checkbox-${option.id}-${option.name}`}
                                onChange={() =>
                                    toggleFilter({
                                        id: option.id,
                                        group: filter.group,
                                    })
                                }
                                checked={
                                    activeFilters.findIndex(
                                        (el) => el.id == option.id && el.group === filter.group,
                                    ) !== -1
                                }
                            />
                            <label
                                htmlFor={`checkbox-${option.id}-${option.name}`}
                                className="cursor-pointer pl-4"
                            >
                                {option.name}
                            </label>
                        </div>
                    ))}
                </div>
            </AccordionTab>
        );
    };

    const buildSwitchFilter = (filter) => {
        return (
            <div
                className="flex w-full items-center justify-between border-b border-gray p-4"
                key={`switch-${filter.group}`}
            >
                <h3 className="text--h3 uppercase text-black">{filter.label}</h3>
                <InputSwitch
                    pt={{ slider: "before:top-[3px] bg-primary" }}
                    onChange={(e) => toggleFilter(filter)}
                    checked={
                        activeFilters.findIndex((el) => el.group === filter.group) !== -1
                    }
                />
            </div>
        );
    };

    const buildMinMaxFilter = (filter) => {

         return (
            <div
                className="flex w-full flex-col gap-4 border-b border-gray p-4 sm:flex-row sm:items-center"
                key={`switch-${filter.group}`}
            >
                <h3 className="text--h3 uppercase text-black">{filter.label}</h3>
                <div className="ml-auto flex items-center">
                    <label className="mr-2 uppercase">
                        {LanguageProvider.get("filters.min")}
                    </label>
                    <Input
                        type="number"
                        className="py-1.5"
                        wrapClassName="w-24 mr-8"
                        onChange={(e) => {
                            const val = e.target.value;
                            setActiveFilters((prev) => {
                                const index = prev.findIndex((el) => el.group === filter.group);
                                if (index === -1) {
                                    return [...prev, { min: val, group: filter.group }];
                                } else {
                                    const newFilters = [...prev];
                                    newFilters[index] = { ...newFilters[index], min: val };
                                    return newFilters;
                                }
                            });
                        }}
                        value={
                            activeFilters.find((el) => el.group === filter.group)?.min ?? ""
                        }
                    />
                    <label className="mr-2 uppercase">
                        {LanguageProvider.get("filters.max")}
                    </label>
                    <Input
                        type="number"
                        className="py-1.5"
                        wrapClassName="w-24"
                        onChange={(e) => {
                            const val = e.target.value;
                            setActiveFilters((prev) => {
                                const index = prev.findIndex((el) => el.group === filter.group);
                                if (index === -1) {
                                    return [...prev, { max: val, group: filter.group }];
                                } else {
                                    const newFilters = [...prev];
                                    newFilters[index] = { ...newFilters[index], max: val };
                                    return newFilters;
                                }
                            });
                        }}
                        value={
                            activeFilters.find((el) => el.group === filter.group)?.max ?? ""
                        }
                    />
                </div>
            </div>
        ); 
    };

    const buildNumberFilter = (filter) => {
        return (
            <div
                className="flex w-full flex-wrap items-center gap-4 border-b border-gray p-4"
                key={`switch-${filter.group}`}
            >
                <h3 className="text--h3 uppercase text-black">{filter.label}</h3>
                <div className="ml-auto flex items-center">
                    <Input
                        type="number"
                        className="py-1.5"
                        wrapClassName="w-24"
                        min={0}
                        max={100}
                        onChange={(e) => {
                            const val = e.target.value;
                            setActiveFilters((prev) => {
                                const index = prev.findIndex((el) => el.group === filter.group);
                                if (index === -1) {
                                    return [...prev, { value: val, group: filter.group }];
                                } else {
                                    const newFilters = [...prev];
                                    newFilters[index] = { ...newFilters[index], value: val };
                                    return newFilters;
                                }
                            });
                        }}
                        value={
                            activeFilters.find((el) => el.group === filter.group)?.value ?? ""
                        }
                    />
                </div>
            </div>
        );
    };

    return (
        <Dialog
            resizable={false}
            visible={visible}
            onHide={onHide}
            className="w-full max-w-3xl"
            draggable={false}
            closable={false}
            dismissableMask
            pt={{
                root: {
                    className: "rounded-xl m-4",
                },
                header: {
                    className: "hidden",
                },
                content: {
                    className: "p-6 sm:p-12 rounded-2xl scrollbar-hide",
                },
            }}
        >
            <div className="flex w-full items-center justify-between">
                <h1 className="text--huge uppercase text-black">
                    {LanguageProvider.get("filters.title")}
                </h1>
                <button className="btn--white p-3" onClick={onHide}>
                    <MdClose className="h-6 w-6" />
                </button>
            </div>
            <div>
                {isLoading ? (
                    <Spinner className="mx-auto my-16 h-16 w-16" />
                ) : (
                    <div className="mt-8">
                        {availableFilters
                            .filter((el) => el.type === "switch")
                            .map((el) => buildSwitchFilter(el))}
                        <Accordion
                            multiple
                            activeIndex={openedTabs}
                            onTabChange={(e) => setOpenedTabs(e.index)}
                        >
                            {availableFilters
                                .filter((el) => el.type === "checkbox")
                                .map((filter, i) => buildCheckBoxFilter(filter, i))}
                        </Accordion>
                        {availableFilters
                            .filter((el) => el.type === "minmax")
                            .map((el) => buildMinMaxFilter(el))}
                        {availableFilters
                            .filter((el) => el.type === "number")
                            .map((el) => buildNumberFilter(el))}
                    </div>
                )}
            </div>
            <div className="mt-8 flex w-full flex-col justify-center gap-4 text-center sm:flex-row sm:gap-8">
                <button
                    className={cn(
                        "text--h2 uppercase",
                        applyButtonClassName ? applyButtonClassName : "btn--secondary",
                    )}
                    onClick={() => onActiveFiltersChange(activeFilters)}
                >
                    {LanguageProvider.get("filters.apply")}
                </button>
                <button
                    className="btn--white text--h2 uppercase"
                    onClick={() => {
                        setActiveFilters([]);
                        onActiveFiltersChange([]);
                    }}
                >
                    {LanguageProvider.get("filters.clear")}
                </button>
            </div>
        </Dialog>
    );
}

export default FiltersDialog;
