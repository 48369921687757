import { lazy } from "react";
import { Navigate, Route } from "react-router-dom";
import LazyComponent from "./LazyComponent";
import Home from "../pages/Home";
const AdminPosition = lazy(() => import("../pages/admin/Position"));
const AdminMenu = lazy(() => import("../pages/admin/Menu"));
const AdminQrcode = lazy(() => import("../pages/admin/Qrcode"));
const AdminSuggestProduct = lazy(() => import("../pages/admin/SuggestProduct"));
const ExploreBars = lazy(() => import("../pages/bars/ExploreBars"));
const BarDetails = lazy(() => import("../pages/bars/BarDetails"));
const ExploreProducts = lazy(() => import("../pages/products/ExploreProducts"));
const UserLogin = lazy(() => import("../pages/login/UserLogin"));
const Congratulations = lazy(() => import("../pages/Congratulations"));
const Profile = lazy(() => import("../pages/user/Profile"));
const EditPassword = lazy(() => import("../pages/user/EditPassword"));
const Favorites = lazy(() => import("../pages/user/Favorites"));
const SuggestBar = lazy(() => import("../pages/user/SuggestBar"));
const AdminLogin = lazy(() => import("../pages/login/AdminLogin"));
const ProductDetails = lazy(() => import("../pages/products/ProductDetails"));
const AdminBar = lazy(() => import("../pages/admin/Bar"));
const Recover = lazy(() => import("../pages/login/Recover"));
const Reset = lazy(() => import("../pages/login/Reset"));
const PasswordRestored = lazy(() => import("../pages/login/PasswordRestored"));
const Faq = lazy(() => import("../pages/Faq"));

export class PagePath {
    static Home = "/";
    static ExploreBars = "/bars";
    static BarsMap = "/bars/map";
    static BarDetails = (id) => `/bars/${id}`;
    static ExploreProducts = "/products";
    static ProductDetails = (id) => `/products/${id}`;
    static RegisterCongratulations = "/register-congratulations";
    static UserLogin = "/user-login";
    static UserSection = "/user";
    static UserProfile = "/user/profile";
    static UserEditPassword = "/user/edit-password";
    static UserFavorites = "/user/favorites";
    static UserSuggestBar = "/user/suggest-bar";
    static AdminLogin = "/admin-login";
    static AdminSection = "/admin";
    static AdminBar = "/admin/bar";
    static AdminPosition = "/admin/position";
    static AdminMenu = "/admin/menu";
    static AdminQrcode = "/admin/qrcode";
    static AdminSuggestProduct = "/admin/suggest-product";
    static Recover = "/recover-password";
    static Reset = "/reset-password";
    static PasswordRestored = "/password-restored";
    static Faq = "/faq";
}

export const baseRoutes = [
    <Route exact path={PagePath.Home} element={<Home />} key="home" />,
    <Route
        exact
        path={PagePath.ExploreBars}
        element={<LazyComponent item={<ExploreBars />} />}
        key="bars"
    />,
    <Route
        exact
        path={PagePath.BarDetails(":id")}
        element={<LazyComponent item={<BarDetails />} />}
        key="bar"
    />,
    <Route
        exact
        path={PagePath.ExploreProducts}
        element={<LazyComponent item={<ExploreProducts />} />}
        key="prod"
    />,
    <Route
        exact
        path={PagePath.ProductDetails(":id")}
        element={<LazyComponent item={<ProductDetails />} />}
        key="prod"
    />,
    <Route
        exact
        path={PagePath.UserLogin}
        element={<LazyComponent item={<UserLogin />} />}
        key="user-login"
    />,
    <Route
        exact
        path={PagePath.AdminLogin}
        element={<LazyComponent item={<AdminLogin />} />}
        key="admin-login"
    />,
    <Route
        exact
        path="/register-congratulations"
        element={<LazyComponent item={<Congratulations />} />}
        key="user-congratulations"
    />,
    <Route
        exact
        path={PagePath.Recover}
        element={<LazyComponent item={<Recover />} />}
        key="recover-password"
    />,
    <Route
        exact
        path={PagePath.Reset}
        element={<LazyComponent item={<Reset />} />}
        key="reset-password"
    />,
    <Route
        exact
        path={PagePath.PasswordRestored}
        element={<LazyComponent item={<PasswordRestored />} />}
        key="password-restored"
    />,
    <Route
        exact
        path={PagePath.Faq}
        element={<LazyComponent item={<Faq />} />}
        key="faq"
    />,
    <Route path="*" element={<Navigate to={PagePath.Home} />} key="not-found" />,
];

export const userRoutes = [
    <Route
        exact
        path={PagePath.UserProfile}
        element={<LazyComponent item={<Profile />} />}
        key="user-profile"
    />,
    <Route
        exact
        path={PagePath.UserEditPassword}
        element={<LazyComponent item={<EditPassword />} />}
        key="user-edit-password"
    />,
    <Route
        exact
        path={PagePath.UserFavorites}
        element={<LazyComponent item={<Favorites />} />}
        key="user-favorites"
    />,
    <Route
        exact
        path={PagePath.UserSuggestBar}
        element={<LazyComponent item={<SuggestBar />} />}
        key="suggest-bar"
    />,
    <Route path="*" element={<Navigate to={PagePath.Home} />} key="not-found" />,
];

export const adminRoutes = [
    <Route
        exact
        path={PagePath.AdminBar}
        element={<LazyComponent item={<AdminBar />} />}
        key="admin-profile"
    />,
    <Route
        exact
        path={PagePath.AdminPosition}
        element={<LazyComponent item={<AdminPosition />} />}
        key="admin-position"
    />,
    <Route
        exact
        path={PagePath.AdminMenu}
        element={<LazyComponent item={<AdminMenu />} />}
        key="admin-menu"
    />,
    <Route
        exact
        path={PagePath.AdminQrcode}
        element={<LazyComponent item={<AdminQrcode />} />}
        key="admin-qrcode"
    />,
    <Route
        exact
        path={PagePath.AdminSuggestProduct}
        element={<LazyComponent item={<AdminSuggestProduct />} />}
        key="admin-suggectproduct"
    />,
    <Route
        exact
        path={PagePath.Faq}
        element={<LazyComponent item={<Faq />} />}
        key="faq"
    />,
    <Route path="*" element={<Navigate to={PagePath.Home} />} key="not-found" />,
];
