import React from "react";

function QrCodeBoldSvg(props) {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25.2498 2H28.7792C30.7285 2 32.3086 3.58017 32.3086 5.52941V9.05882M25.2498 32H28.7792C30.7285 32 32.3086 30.4199 32.3086 28.4706V24.9412M9.36742 2H5.83801C3.88876 2 2.30859 3.58017 2.30859 5.52941V9.05882M9.36742 32H5.83801C3.88876 32 2.30859 30.4199 2.30859 28.4706V24.9412M9.36742 26.7059H12.8968C13.8715 26.7059 14.6615 25.9158 14.6615 24.9412V21.4118C14.6615 20.4371 13.8715 19.6471 12.8968 19.6471H9.36742C8.39281 19.6471 7.60271 20.4371 7.60271 21.4118V24.9412C7.60271 25.9158 8.39281 26.7059 9.36742 26.7059ZM21.7204 20.5294C21.7204 21.0166 21.3252 21.4118 20.838 21.4118C20.3508 21.4118 19.9557 21.0166 19.9557 20.5294C19.9557 20.0422 20.3508 19.6471 20.838 19.6471C21.3252 19.6471 21.7204 20.0422 21.7204 20.5294ZM21.7204 25.8235C21.7204 26.3108 21.3252 26.7059 20.838 26.7059C20.3508 26.7059 19.9557 26.3108 19.9557 25.8235C19.9557 25.3363 20.3508 24.9412 20.838 24.9412C21.3252 24.9412 21.7204 25.3363 21.7204 25.8235ZM27.0145 25.8235C27.0145 26.3108 26.6194 26.7059 26.1321 26.7059C25.6449 26.7059 25.2498 26.3108 25.2498 25.8235C25.2498 25.3363 25.6449 24.9412 26.1321 24.9412C26.6194 24.9412 27.0145 25.3363 27.0145 25.8235ZM27.0145 20.5294C27.0145 21.0166 26.6194 21.4118 26.1321 21.4118C25.6449 21.4118 25.2498 21.0166 25.2498 20.5294C25.2498 20.0422 25.6449 19.6471 26.1321 19.6471C26.6194 19.6471 27.0145 20.0422 27.0145 20.5294ZM9.36742 14.3529H12.8968C13.8715 14.3529 14.6615 13.5629 14.6615 12.5882V9.05882C14.6615 8.08421 13.8715 7.29412 12.8968 7.29412H9.36742C8.39281 7.29412 7.60271 8.08421 7.60271 9.05882V12.5882C7.60271 13.5629 8.39281 14.3529 9.36742 14.3529ZM21.7204 14.3529H25.2498C26.2244 14.3529 27.0145 13.5629 27.0145 12.5882V9.05882C27.0145 8.08421 26.2244 7.29412 25.2498 7.29412H21.7204C20.7457 7.29412 19.9557 8.08421 19.9557 9.05882V12.5882C19.9557 13.5629 20.7457 14.3529 21.7204 14.3529Z"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default QrCodeBoldSvg;
