import * as React from "react";
const BarBoldSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={34}
    fill="none"
    {...props}
  >
    <path
      stroke="#171717"
      strokeLinecap="round"
      strokeWidth={3}
      d="M11.43 2.178 4.286 4.593c-1.41.476-2.363 1.823-2.363 3.34v20.549C1.923 30.425 3.466 32 5.368 32H26.9c1.903 0 3.445-1.575 3.445-3.518V14.8c0-1.207-.605-2.33-1.605-2.975L14.352 2.544a3.383 3.383 0 0 0-2.922-.366Zm-1.755 13.99h2.583a.87.87 0 0 0 .862-.88V12.65a.87.87 0 0 0-.862-.88H9.675a.87.87 0 0 0-.862.88v2.639c0 .485.386.88.862.88Zm14.64 15.392V20.126a.87.87 0 0 0-.86-.88h-4.307a.87.87 0 0 0-.861.88V31.56h6.029Z"
    />
  </svg>
);
export default BarBoldSvg;
