import React from "react";
import cn from "../../../utils/classNames";

function Spinner(props) {
  return (
    <div
      className={cn(
        "h-8 w-8 animate-spin rounded-full border-2 border-solid",
        props.className,
        "border-t-transparent",
      )}
    ></div>
  );
}

export default Spinner;
