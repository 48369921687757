import React from "react";
import cn from "../../../utils/classNames";
import { ProgressSpinner } from "primereact/progressspinner";
import { LanguageProvider } from "../../../core/language-provider";

function PageSpinner(props) {
  return (
    <div
      className={cn(
        "flex min-h-80 flex-1 flex-col items-center justify-center text-center text-black",
        props.className,
      )}
    >
      <div
        className={
          "h-16 w-16 animate-spin rounded-full border-2 border-solid border-t-transparent"
        }
      />
      <span className="mt-2 text-h3">
        {LanguageProvider.get("defaults.loading")}
      </span>
    </div>
  );
}

export default PageSpinner;
