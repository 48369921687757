import React from "react";
import cn from "../../utils/classNames";
import { MdClose } from "react-icons/md";

function BarMood(props) {
    const name = props.name;
    const canDelete = props.canDelete ?? false;
    const onDelete = props.onDelete;

    return (
        <div
            className={cn(
                "text--smallP flex w-max items-center rounded-md bg-neutral-100 px-4 py-1 text-center uppercase",
                props.className,
            )}
        >
            <span>{name}</span>
            {canDelete && (
                <button className="ml-1" onClick={() => onDelete()}>
                    <MdClose className="h-4 w-4" />
                </button>
            )}
        </div>
    );
}

export default BarMood;
