import classNames from "classnames";
import SpinnerBlock from "../SpinnerBlock/Spinner";

function Button(props) {
  return (
    <button
      type={props.type}
      onClick={props.onClick}
      disabled={props.isLoading || props.disabled}
      className={classNames(
        !props.disabled && "btn--disabled",
        props.className,
      )}
    >
      {props.isLoading ? (
        <SpinnerBlock className="h-6 w-6" />
      ) : (
        <>{props.title ? <span>{props.title}</span> : props.children}</>
      )}
    </button>
  );
}

export default Button;
