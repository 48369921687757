import Swal from "sweetalert2";
import { LanguageProvider } from "../core/language-provider";
import withReactContent from "sweetalert2-react-content";

const ReactSwal = withReactContent(Swal);

const Toast = ReactSwal.mixin({
  toast: true,
  position: "top-right",
  iconColor: "white",
  customClass: {
    popup: "colored-toast",
  },
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
});

export function requestErrorToast(customMessage = null) {
  let message = customMessage ?? LanguageProvider.get("errors.try_later");

  return Toast.mixin({
    icon: "error",
    title: message,
  });
}

export function requestSuccessToast(customMessage = null) {
  let message =
    customMessage ?? LanguageProvider.get("settings.general_success");

  return Toast.mixin({
    icon: "success",
    title: message,
  });
}
