import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "../components/BodyElements/HeaderBlock/Header";
import Footer from "../components/BodyElements/FooterBlock/Footer";
import { PagePath } from "../utils/routes";
import { userStore } from "../store/user";

function BaseLayout() {
  const location = useLocation();
  const userState = userStore();

  return (
    <>
      <Header
        showNavLinks={
          !location.pathname.startsWith(PagePath.UserLogin) &&
          !location.pathname.startsWith(PagePath.AdminLogin)
        }
        showBarLinks={
          location.pathname.startsWith(PagePath.UserSection) ||
          location.pathname.startsWith(PagePath.AdminSection)
        }
      />
      <Outlet />
      <Footer
        short={
          location.pathname.startsWith(PagePath.UserSection) ||
          location.pathname.startsWith(PagePath.AdminSection) ||
          location.pathname === PagePath.RegisterCongratulations
        }
      />
    </>
  );
}

export default BaseLayout;
