export const settings = {
  title: "DBARS",
  apiUrl: "https://api.dbars.it/api",
  ckdPath: "https://api.dbars.it/",
  config: {
    limit: 60,
    sortingField: "id",
    sortingOrder: "desc",
  },
  //googleApiKey: "AIzaSyDimZ_LfSQ3qFkmWOlbJzN0OVwx4NulMaI",
  googleApiKey: "AIzaSyB0JItfx2zvDnKkmxIBt_wru36tOVcx1iM", //maps
};
