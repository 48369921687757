import React from "react";

function QrCodeLightSvg(props) {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24.2498 1H27.7792C29.7285 1 31.3086 2.58017 31.3086 4.52941V8.05882M24.2498 31H27.7792C29.7285 31 31.3086 29.4199 31.3086 27.4706V23.9412M8.36742 1H4.83801C2.88876 1 1.30859 2.58017 1.30859 4.52941V8.05882M8.36742 31H4.83801C2.88876 31 1.30859 29.4199 1.30859 27.4706V23.9412M8.36742 25.7059H11.8968C12.8715 25.7059 13.6615 24.9158 13.6615 23.9412V20.4118C13.6615 19.4371 12.8715 18.6471 11.8968 18.6471H8.36742C7.39281 18.6471 6.60271 19.4371 6.60271 20.4118V23.9412C6.60271 24.9158 7.39281 25.7059 8.36742 25.7059ZM20.7204 19.5294C20.7204 20.0166 20.3252 20.4118 19.838 20.4118C19.3508 20.4118 18.9557 20.0166 18.9557 19.5294C18.9557 19.0422 19.3508 18.6471 19.838 18.6471C20.3252 18.6471 20.7204 19.0422 20.7204 19.5294ZM20.7204 24.8235C20.7204 25.3108 20.3252 25.7059 19.838 25.7059C19.3508 25.7059 18.9557 25.3108 18.9557 24.8235C18.9557 24.3363 19.3508 23.9412 19.838 23.9412C20.3252 23.9412 20.7204 24.3363 20.7204 24.8235ZM26.0145 24.8235C26.0145 25.3108 25.6194 25.7059 25.1321 25.7059C24.6449 25.7059 24.2498 25.3108 24.2498 24.8235C24.2498 24.3363 24.6449 23.9412 25.1321 23.9412C25.6194 23.9412 26.0145 24.3363 26.0145 24.8235ZM26.0145 19.5294C26.0145 20.0166 25.6194 20.4118 25.1321 20.4118C24.6449 20.4118 24.2498 20.0166 24.2498 19.5294C24.2498 19.0422 24.6449 18.6471 25.1321 18.6471C25.6194 18.6471 26.0145 19.0422 26.0145 19.5294ZM8.36742 13.3529H11.8968C12.8715 13.3529 13.6615 12.5629 13.6615 11.5882V8.05882C13.6615 7.08421 12.8715 6.29412 11.8968 6.29412H8.36742C7.39281 6.29412 6.60271 7.08421 6.60271 8.05882V11.5882C6.60271 12.5629 7.39281 13.3529 8.36742 13.3529ZM20.7204 13.3529H24.2498C25.2244 13.3529 26.0145 12.5629 26.0145 11.5882V8.05882C26.0145 7.08421 25.2244 6.29412 24.2498 6.29412H20.7204C19.7457 6.29412 18.9557 7.08421 18.9557 8.05882V11.5882C18.9557 12.5629 19.7457 13.3529 20.7204 13.3529Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default QrCodeLightSvg;
