import * as React from "react";
const BarLightSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={31}
    height={32}
    fill="none"
    {...props}
  >
    <path
      stroke="#171717"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M10.544 1.178 3.4 3.593c-1.41.476-2.362 1.823-2.362 3.34v20.549C1.038 29.425 2.58 31 4.483 31h21.53c1.903 0 3.446-1.575 3.446-3.518V13.8c0-1.207-.606-2.33-1.605-2.975L13.467 1.544a3.383 3.383 0 0 0-2.923-.366ZM8.79 15.168h2.584a.87.87 0 0 0 .86-.88V11.65a.87.87 0 0 0-.86-.88H8.789a.87.87 0 0 0-.861.88v2.639c0 .485.385.88.86.88Zm14.64 15.392V19.126a.87.87 0 0 0-.86-.88h-4.306a.87.87 0 0 0-.862.88V30.56h6.029Z"
    />
  </svg>
);
export default BarLightSvg;
