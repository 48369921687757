import * as React from "react";

const MapBoldSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={27}
    height={34}
    fill="none"
    {...props}
  >
    <path
      stroke="#171717"
      strokeLinecap="round"
      strokeWidth={3}
      d="M24.923 14.101c0 4.742-5.867 12.593-9.278 16.757a3.055 3.055 0 0 1-4.764 0C7.47 26.694 1.603 18.843 1.603 14.1 1.603 7.418 6.823 2 13.263 2s11.66 5.418 11.66 12.101Z"
    />
    <path
      stroke="#171717"
      strokeLinecap="round"
      strokeWidth={3}
      d="M17.595 13.66a4.331 4.331 0 1 1-8.663 0 4.331 4.331 0 0 1 8.663 0Z"
    />
  </svg>
);
export default MapBoldSvg;
