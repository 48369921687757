import React from "react";

function ArrowRightSvg(props) {
  return (
    <svg
      width="31"
      height="18"
      viewBox="0 0 31 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M30.0457 9.54549C30.485 9.10615 30.485 8.39384 30.0457 7.9545L22.8862 0.795047C22.4469 0.355707 21.7346 0.355707 21.2952 0.795047C20.8559 1.23439 20.8559 1.9467 21.2952 2.38604L27.6592 8.75L21.2952 15.114C20.8559 15.5533 20.8559 16.2656 21.2952 16.7049C21.7346 17.1443 22.4469 17.1443 22.8862 16.7049L30.0457 9.54549ZM0.000183204 9.875L29.2502 9.875L29.2502 7.625L0.000183007 7.625L0.000183204 9.875Z"
        fill="#696969"
      />
    </svg>
  );
}

export default ArrowRightSvg;
