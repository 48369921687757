import * as React from "react";
const LogoWhiteSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={158}
    height={40}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M20.327 7.275h.506c5.723.014 11.447.004 17.168.053 1.983.016 3.902.448 5.672 1.4 2.604 1.4 3.842 3.65 4.014 6.55.185 3.114-.973 5.545-3.674 7.174-.346.209-.705.396-1.107.623.722.321 1.39.605 2.046.914 5.665 2.662 5.28 10.855.81 13.756-2.334 1.514-4.912 2.178-7.622 2.204-7.153.069-14.307.031-21.46.035-.095 0-.187-.027-.356-.053.298-.725.575-1.408.86-2.086.523-1.252.97-2.543 1.597-3.742 1.28-2.45 1.648-5.011 1.589-7.77-.13-6.05-.04-12.105-.04-18.157V7.28l-.003-.006ZM96.411 29.73v9.819h-8.904V6.929c.208-.013.381-.03.554-.028 5.236.01 10.473.005 15.71.043 2.083.016 4.114.392 6.041 1.222 4.226 1.826 6.447 5.627 6.218 10.49-.203 4.324-2.221 7.414-6.071 9.322-.157.079-.308.171-.494.278l7.612 11.305c-.259.02-.443.045-.628.045-3.015.002-6.029-.012-9.043.016-.506.004-.769-.174-1.034-.582-1.903-2.943-3.839-5.867-5.744-8.81-.236-.363-.473-.523-.915-.51-1.071.036-2.144.013-3.304.013l.002-.002ZM85.139 39.984c-3.084 0-6.123.008-9.16-.022-.19 0-.46-.268-.548-.475-.661-1.553-1.301-3.117-1.915-4.69-.161-.414-.353-.581-.824-.58-3.73.022-7.46.02-11.192 0-.453-.002-.667.135-.83.564a145.014 145.014 0 0 1-1.88 4.706c-.086.205-.358.471-.549.474-2.988.03-5.974.021-9.058.021.64-1.524 1.251-2.98 1.864-4.436 3.873-9.197 7.75-18.392 11.609-27.595.204-.487.418-.702.985-.694 2.401.038 4.803.024 7.205.01.392-.002.633.057.812.485 4.473 10.666 8.964 21.324 13.452 31.984.017.043.011.098.027.246l.002.002ZM137.409 39.888h-16.911l5.887-13.986c-.677-.357-1.34-.672-1.97-1.045-2.898-1.707-4.064-4.355-3.987-7.618.096-4.123 1.999-7.107 5.696-8.937.459-.227.718-.219 1.071.227 3.306 4.174 6.647 8.32 9.967 12.483.163.203.306.495.308.746.016 5.95.01 11.9.006 17.85 0 .073-.033.144-.067.282v-.002Z"
    />
    <path
      fill="#99CA3C"
      d="m17.5 7.692.033 1.104c-.012.252-.036.507-.036.761 0 5.325-.003 10.65 0 15.973 0 1.303.024 2.608.038 3.912-.228.595-.442 1.198-.687 1.788-1.095 2.622-2.217 5.232-3.286 7.866-.222.544-.497.742-1.07.69-1.899-.167-3.857-.067-5.693-.495C2.484 38.29.012 34.988 0 30.621c-.012-4.332 2.559-7.764 6.779-8.837 1.37-.347 2.821-.386 4.24-.5.461-.038.724-.125.914-.584 1.727-4.188 3.477-8.365 5.227-12.543.073-.175.228-.315.343-.473l-.003.008Z"
    />
    <path
      fill="#fff"
      d="M127.907 7h20.511c-.582.751-1.096 1.431-1.628 2.1a6023.71 6023.71 0 0 1-7.32 9.165c-.748.935-1.788.945-2.594-.041-1.766-2.155-3.493-4.343-5.235-6.516-1.056-1.317-2.113-2.63-3.167-3.947-.157-.195-.3-.402-.567-.761ZM138.889 39.789V21.551c3.571-.176 7.525 3.935 7.917 8.215.449 4.925-3.008 9.349-7.917 10.023Z"
    />
    <path
      fill="#FFD200"
      d="M151.356 5.773h-5.951c-.379-2.005 1.477-4.594 3.841-5.43a5.944 5.944 0 0 1 7.258 2.857 6.012 6.012 0 0 1-1.697 7.582c-2.194 1.654-5.49 1.582-7.299-.189l3.846-4.816.002-.004Z"
    />
    <path fill="#D4190C" d="m17.5 7.898.003-.006.038-.012-.041.018Z" />
  </svg>
);
export default LogoWhiteSvg;
