import React from "react";
import cn from "../../utils/classNames";
import { MdClose } from "react-icons/md";

function BarCategory(props) {
  const canDelete = props.canDelete ?? false;
  const onDelete = props.onDelete;
  const name = props.name;

  return (
    <div
      className={cn(
        "text--smallP flex w-max items-center rounded-md bg-secondary px-4 py-1 text-center uppercase",
        props.className,
      )}
    >
      <span>{name}</span>
      {canDelete && (
        <button className="ml-1" onClick={() => onDelete()}>
          <MdClose className="h-4 w-4" />
        </button>
      )}
    </div>
  );
}

export default BarCategory;
