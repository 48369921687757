import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LanguageProvider } from "../core/language-provider";
import heroImage from "../assets/background.jpeg";
import SearchBarBlock from "../components/BodyElements/SearchBarBlock/SearchBar";
import ArrowLeftSvg from "../assets/icons/arrow-left";
import ArrowRightSvg from "../assets/icons/arrow-right";
import BarCard from "../components/BarElements/BarCard";
import SuggestedBarsCard from "../components/BarElements/SuggestedBarsCard";
import { PagePath } from "../utils/routes";
import { DataManager } from "../core/data-manager";
import { useQuery } from "@tanstack/react-query";
import Spinner from "../components/BodyElements/SpinnerBlock/Spinner";
import AboutUsImage from "../assets/about_us.jpeg";
import { CustomDataManager } from "../config/custom";
import { ReactComponent as SearchSvg } from "../assets/icons/search.svg";

export default function Home() {
    const navigate = useNavigate();

    const [place, setPlace] = useState("");
    const suggestedBarsRef = React.useRef(null);

    const suggestedBarsQuery = useQuery({
        queryKey: ["suggestedBars"],
        queryFn: async () => {
            const sorting = {
                field: "id",
                order: "desc",
            };
            const res = await DataManager.getItems("box_home", 1, 20, sorting);
            if (res.success === 1) {
                return res.body;
            }

            return new Error(LanguageProvider.get("errors.get"));
        },
        refetchOnWindowFocus: false,
        retry: false,
    });

    const bestBarsInMilanQuery = useQuery({
        queryKey: ["bestBarsInMilan"],
        queryFn: async () => {
            const sorting = {
                field: "",
                order: "RAND()",
            };
            const constraints = [
                {
                    fieldName: 'approved',
                    type: "value",
                    value: 1
                },
                {
                    fieldName: 'latitude',
                    type: 'range',
                    min: parseFloat('45.2863'),
                    max: parseFloat('45.5351'),
                    range_type: 'STR'
                },
                {
                    fieldName: 'longitude',
                    type: 'range',
                    min: parseFloat('9.0504'),
                    max: parseFloat('9.2854'),
                    range_type: 'STR'
                }
            ];

            const res = await DataManager.searchItems(
                "bar",
                1,
                4,
                sorting,
                "",
                [],
                constraints,
            );

            if (res.success === 1) {
                return res.body;
            }

            return new Error(LanguageProvider.get("errors.get"));
        },
        refetchOnWindowFocus: false,
        retry: false,
    });

    const bestBarsInRomeQuery = useQuery({
        queryKey: ["bestBarsInRome"],
        queryFn: async () => {
            const sorting = {
                field: "",
                order: "RAND()",
            };
            const constraints = [
                {
                    fieldName: 'approved',
                    type: "value",
                    value: 1
                },
                {
                    fieldName: 'latitude',
                    type: 'range',
                    min: parseFloat('41.7999'),
                    max: parseFloat('41.9836'),
                    range_type: 'STR'
                },
                {
                    fieldName: 'longitude',
                    type: 'range',
                    min: parseFloat('12.3547'),
                    max: parseFloat('12.5731'),
                    range_type: 'STR'
                }
            ];

            const res = await DataManager.searchItems(
                "bar",
                1,
                4,
                sorting,
                "",
                [],
                constraints,
            );

            if (res.success === 1) {
                return res.body;
            }

            return new Error(LanguageProvider.get("errors.get"));
        },
        refetchOnWindowFocus: false,
        retry: false,
    });

    const searchPlaceSuggestions = async (event) => {
        const searchName = event.query;

        const suggestions = await CustomDataManager.searchPosition(searchName);
        return suggestions.places;
    };

    const search = async () => {
        if (typeof place === "string") {
            navigate(`${PagePath.ExploreBars}?formattedAddress=${place}`);
        } else {
            navigate(
                //`${PagePath.ExploreBars}?lat=${place.location.latitude}&lng=${place.location.longitude}&formattedAddress=${place.formattedAddress}`,
                `${PagePath.ExploreBars}?formattedAddress=${place.formattedAddress}`,
            );
        }
    };

    const scrollSuggestedBars = (amount) => {
        const actualScroll = suggestedBarsRef.current.scrollLeft;
        suggestedBarsRef.current.scrollTo({
            left: actualScroll + amount,
            behavior: "smooth",
        });
    };

    const buildBarsSection = (query) => {
        if (query.isLoading) {
            return <Spinner />;
        }

        if (query.data && !query.isError) {
            return query.data.map((el) => <BarCard bar={el} key={`bar-${el.id}`} />);
        }

        return <div></div>;
    };

    const buildSuggestedBarsSection = (query) => {
        if (query.isLoading) {
            return <Spinner />;
        }

        if (query.data && !query.isError) {
            return query.data.map((el) => (
                <SuggestedBarsCard box={el} key={`bar-${el.id}`} />
            ));
        }

        return <div></div>;
    };

    return (
        <main>
            <section className="relative flex h-[80vh] flex-col items-center justify-center px-6 text-center sm:p-16">
                <img
                    src={heroImage}
                    alt="hero"
                    className="absolute z-[-1] h-full w-full object-cover"
                />
                {/*
                <h1 className="text--huge mb-12 text-center uppercase text-white">
                    Scopri tutti i bar
                </h1>
                <button className="btn--secondary px-0" onClick={() => { navigate('bars') }}>
                    <SearchSvg className="mx-3 block h-6 w-6 sm:hidden" />
                    <span className="text--h2 px-6 sm:blocks">
                        Vedi tutti
                    </span>
                </button>
                */}
                <h1 className="text--huge mb-12 text-center uppercase text-white">
                    {LanguageProvider.get("home.title")}
                </h1>
                <SearchBarBlock
                    showClearIcon={false}
                    showNameInput={false}
                    showFiltersButton={false}
                    namePlaceholder={LanguageProvider.get("searchbar.barPlaceholder")}
                    place={place}
                    onSearchPlaceSuggestions={searchPlaceSuggestions}
                    onPlaceChange={(e) => setPlace(e.value)}
                    onSearch={search}
                    className="w-full max-w-3xl"
                    shortSearchButton
                />

                

                {/* <div className="flex flex-col items-center gap-y-3 rounded-xl sm:flex-row sm:border sm:border-gray sm:bg-white sm:p-2
                         flex-row border-gray bg-white px-2"> */}

                {/* </div> */}



            </section>
            <div className="pt-16">
                <section>
                    <div className="page-content flex items-center justify-between py-8">
                        <h1 className="text--h1 uppercase">
                            {LanguageProvider.get("home.suggested_title")}
                        </h1>
                        <div className="hidden items-center gap-4 md:flex">
                            <button
                                className="btn--white p-3"
                                onClick={() => scrollSuggestedBars(-500)}
                            >
                                <ArrowLeftSvg className="h-6 w-6" />
                            </button>
                            <button
                                className="btn--white p-3"
                                onClick={() => scrollSuggestedBars(500)}
                            >
                                <ArrowRightSvg className="h-6 w-6" />
                            </button>
                        </div>
                    </div>
                    <div
                        className="overflow-x-auto scrollbar-hide"
                        ref={suggestedBarsRef}
                    >
                        <div className="page-content flex gap-6 py-0 sm:gap-12">
                            {buildSuggestedBarsSection(suggestedBarsQuery)}
                            <div className="w-12 flex-shrink-0" />
                        </div>
                    </div>
                </section>
                <section className="page-content mt-8">
                    <div className="flex flex-col  items-start justify-between sm:flex-row sm:items-center">
                        <h1 className="text--h1 uppercase">
                            {LanguageProvider.get("home.best_bars_title")} MILANO
                        </h1>
                        <button
                            className="btn--white mt-5 flex flex-shrink-0 items-center gap-4 px-4 py-2 uppercase sm:mt-0"
                            onClick={() =>
                                navigate(`${PagePath.ExploreBars}?formattedAddress=Milano`, {
                                    state: {
                                        title: `${LanguageProvider.get(
                                            "home.best_bars_title",
                                        )} MILANO`,
                                    },
                                })
                            }
                        >
                            <span>{LanguageProvider.get("home.view_all")}</span>
                            <ArrowRightSvg className="h-6 w-6" />
                        </button>
                    </div>
                    <div className="mt-8 grid grid-cols-bars gap-8">
                        {buildBarsSection(bestBarsInMilanQuery)}
                    </div>
                </section>
                <section className="page-content">
                    <div className="flex flex-col  items-start justify-between sm:flex-row sm:items-center">
                        <h1 className="text--h1 uppercase">
                            {LanguageProvider.get("home.best_bars_title")} ROMA
                        </h1>
                        <button
                            className="btn--white mt-5 flex flex-shrink-0 items-center gap-4 px-4 py-2 uppercase sm:mt-0"
                            onClick={() =>
                                navigate(`${PagePath.ExploreBars}?formattedAddress=Roma`, {
                                    state: {
                                        title: `${LanguageProvider.get(
                                            "home.best_bars_title",
                                        )} ROMA`,
                                    },
                                })
                            }
                        >
                            <span>{LanguageProvider.get("home.view_all")}</span>
                            <ArrowRightSvg className="h-6 w-6" />
                        </button>
                    </div>
                    <div className="mt-8 grid grid-cols-bars gap-8">
                        {buildBarsSection(bestBarsInRomeQuery)}
                    </div>
                </section>
            </div>
            <div>
                <section className="page-content mb-8">
                    <h1 className="text--h1">
                        {LanguageProvider.get("home.about_us_title")}
                    </h1>
                    <img
                        src={AboutUsImage}
                        alt="about us and our activity"
                        className="my-8 h-80 w-full object-cover"
                    />
                    <p>{LanguageProvider.get("home.about_us_description")}</p>
                </section>
            </div>
        </main>
    );
}
